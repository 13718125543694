import IconLayoutGroup from '../../icons/LayoutGroup';
import IconRocket from '../../icons/Rocket';
import IconUsers from '../../icons/Users';
import MenuProps from './index';

const menu: MenuProps = () => [
  {
    acl: {
      admin: true,
    },
    iconLeft: IconUsers,
    name: 'Utilisateurs',
    path: '/admin/users/',
    profil: 'admin',
    to: '/admin/users/',
  },
  {
    acl: {
      admin: true,
    },
    iconLeft: IconRocket,
    name: 'Espaces',
    path: '/admin/espaces/',
    profil: 'admin',
    to: '/admin/espaces/',
  },
  {
    acl: {
      admin: true,
    },
    iconLeft: IconLayoutGroup,
    name: 'Schémas',
    path: '/admin/schemas/',
    profil: 'admin',
    to: '/admin/schemas/',
  },
];

export default menu;
