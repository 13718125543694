import { useSelector } from '@innedit/formidable';
import { DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC } from 'react';

export interface UserProps {
  user: DocumentType<UserType>;
}

type UserKind = 'admin' | 'editor' | 'owner';

function requireUser<P>(
  ComposedComponent: FC<P & UserProps>,
  type?: UserKind | UserKind[],
): FC<P> {
  return (props => {
    const { user } = useSelector((globalState: any) => globalState.cms);

    if (!user) {
      return <div>L&apos;utilisateur est obligatoire</div>;
    }

    if (type && !(Array.isArray(type) ? type : [type]).includes(user.type)) {
      return <div>L&apos;utilisateur {type} obligatoire</div>;
    }

    return <ComposedComponent {...props} user={user} />;
  }) as FC<P>;
}

export default requireUser;
